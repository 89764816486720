/**
 * 公共接口
 */
export default SERVER => ({
  /**
   * 用户基本信息
   * method GET
   */
  USER_DETAIL: `${SERVER}/api/user/profile`,

  /**
   * 我的团队
   * method GET
   */
  GET_MY_TEAM: `${SERVER}/api/user/invites`,

  /**
   * 用户实名认证 获取，提交
   * method GET / POST
   */
  USER_KYC: `${SERVER}/api/user/kyc`,
  
  /**
   * 修改密码
   * method POST
   */
  CHANGE_PWD: `${SERVER}/api/user/password`,

  /**
   * 设置交易密码
   * method POST
   */
  SET_TRADE_PWD: `${SERVER}/api/user/set_trade_pass`,

  /**
   * 修改交易密码
   * method POST
   */
  CHANGE_TRADE_PWD: `${SERVER}/api/user/change_trade_pass`,

  /**
   * 校验交易密码
   * method POST
   */
  CHECK_TRADE_PWD: `${SERVER}/api/user/check_trade_pass`,
  
  /**
   * 收藏列表
   * method GET
   */
  COIN_FAV_LIST: `${SERVER}/api/fav/list`,
  /**
   * 添加收藏
   * method POST
   */
  COIN_FAV_ADD: `${SERVER}/api/fav/add`,
  /**
   * 删除收藏
   * method POST
   */
  COIN_FAV_DEL: `${SERVER}/api/fav/del`,
  
  /**
   * 贷款配置
   * method GET
   */
  USER_LOAN_CONFIG: `${SERVER}/api/config/credit`,

  /**
   * 贷款列表
   * method GET
   */
  USER_LOAN_LIST: `${SERVER}/api/credit/list`,

  /**
   * 贷款添加
   * method GET
   */
  USER_LOAN_ADD: `${SERVER}/api/credit/add`,
});
