/**
 * index.js for translation
 **/
import setLanguage from 'utils/language';
import en from './en';
import ja from './ja';
import ko from './ko';
import fr from './fr';
import zh from './zh';
import es from './es';
import de from './de';

const languages = [
    { code: 'en', language: en, name: 'English' },
    { code: 'ja', language: ja, name: 'Japanese' },
    { code: 'ko', language: ko, name: 'Korean' },
    { code: 'fr', language: fr, name: 'French' },
    { code: 'zh', language: zh, name: 'Chinese' },
    { code: 'es', language: es, name: 'Spain' },
    { code: 'de', language: de, name: 'Germany' },
];
let language = null;

export const getLanguage = () => language;

export default (code = 'default') => {
    language = setLanguage(code, languages);
    return language.language;
};
